import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`:02 Pause Back Squats 5×2\\@80% 1RM`}</p>
    <p>{`Gute Ham Raise 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`For Time:`}</p>
    <p>{`20-DB Box Step Ups (10/leg, 35/25’s, 24/20″)(RX+50/35’s)`}</p>
    <p>{`30-GHD Situps`}</p>
    <p>{`40-Burpees`}</p>
    <p>{`30-GHD Situps`}</p>
    <p>{`20-DB Box Step Ups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      